import * as React from 'react'
import { useState, useEffect } from "react";
import Thumbnail from "./thumbnails";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { navigate } from "gatsby";

export default function ListThumbnail({ 
    carousels,
    selectedUuids,
    setSelectedUuids,
    expandedCarouselUuid,
    setExpandedCarouselUuid,
}) {
    const [selectedCarousel, setSelectedCarousel] = useState(carousels[0]);

    const getDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    }

    const onCheckClick = (e) => {
        e.preventDefault();

        if (selectedUuids.some((uuid) => uuid === selectedCarousel.carousel_uID)) {
            setSelectedUuids((uuids) => uuids.filter((uuid) => uuid !== selectedCarousel.carousel_uID));
        } else {
            setSelectedUuids((uuids) => [...uuids, selectedCarousel.carousel_uID]);
        }
    }

    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        setIsActive(expandedCarouselUuid === selectedCarousel.carousel_uID);
    }, [expandedCarouselUuid, selectedCarousel])
    
    const showCarouselImages = React.useCallback((e) => {
        e.stopPropagation();
        setExpandedCarouselUuid(selectedCarousel.carousel_uID);
    }, [selectedCarousel])

    const toggleShowCarouselImages = React.useCallback((e) => {
        e.stopPropagation();
        if (expandedCarouselUuid) {
            setExpandedCarouselUuid(null);
        } else {
            setExpandedCarouselUuid(selectedCarousel.carousel_uID);
        }
    }, [expandedCarouselUuid, selectedCarousel])

    const [productImages, setProductImages] = useState([]);
    useEffect(() => {
        const carousel = JSON.parse(selectedCarousel.carousel);
        setProductImages(Object.keys(carousel).map((key) => carousel[key][0].value[0]));
    }, [selectedCarousel])

    const handleClickCarouselUid = (Uuid) => {
        localStorage.setItem("selectedCarouselUuids", JSON.stringify([Uuid]));
        navigate("/my-creations-view");
    }

    return (
        <li>
            <div className={`review-accord-link ${isActive ? 'active': ''}`} onClick={showCarouselImages}>
                <div data-label="ID" className="col-1">
                    <div className="thumbs">
                        <p>
                            <a href="#" onClick={() => handleClickCarouselUid(selectedCarousel.carousel_uID)}>
                                {selectedCarousel.carousel_uID}
                            </a>
                        </p>
                        <div className="aside">
                            <ul>
                                <Thumbnail
                                    carousel={selectedCarousel}
                                    selectedUuids={selectedUuids}
                                    setSelectedUuids={setSelectedUuids}
                                />
                            </ul>
                        </div>
                    </div>
                </div>

                <div data-label="Date" className="col-2">{getDate(selectedCarousel.createdAt)}</div>

                <div data-label="Credits" className="col-3">
                    <span className="arrow" onClick={toggleShowCarouselImages}>Arrow</span>
                </div>

                <div data-label="Selected" className="col-4">
                    <div className="check-options">
                        <input 
                            id="checkbox" 
                            name="checkbox" 
                            type="checkbox" 
                            className="styled" 
                            checked={selectedUuids.some((uuid) => uuid === selectedCarousel.carousel_uID)}
                            readOnly 
                        />
                        <label htmlFor="checkbox" onClick={onCheckClick} />
                    </div>
                </div>
            </div>

            {isActive && (
                <div className={`review-product-slider active`}>
                    <Swiper
                        style={{ 
                            padding: '0 40px',
                            '--swiper-navigation-size': '32px',
                            '--swiper-navigation-top-offset': '50%',
                            '--swiper-navigation-sides-offset': '0px',
                            '--swiper-navigation-color': '#5757ff',
                        }}
                        cssMode={true}
                        speed={1000}
                        spaceBetween={10}
                        slidesPerView={8}
                        breakpoints={{
                            320: {
                                slidesPerView: 3,
                            },
                            640: {
                                slidesPerView: 5,
                            },
                            900: {
                                slidesPerView: 8,
                            },
                            1200: {
                                slidesPerView: 10,
                            },
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        navigation={true}
                        modules={[Autoplay, Navigation]}
                        className="thumbnails-pagination-1"
                    >
                        {productImages.map((image, k) => (
                            <SwiperSlide key={k}>
                                <div className="item">
                                    <a href="#">
                                        <LazyLoadImage src={`${image}?version=120`} alt="review-slider-1" />
                                    </a>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </li>
    )
}
