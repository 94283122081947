import * as React from 'react';
import { withPrefix } from "gatsby";

export default function Thumbnails({ 
    carousel,
    selectedUuids,
    setSelectedUuids,
}) {
    const isChecked = selectedUuids.some((uuid) => uuid === carousel.carousel_uID);
    
    const onProductClick = (e) => {
        e.preventDefault();

        if (selectedUuids.some((uuid) => uuid === carousel.carousel_uID)) {
            setSelectedUuids((uuids) => uuids.filter((uuid) => uuid !== carousel.carousel_uID));
        } else {
            setSelectedUuids((uuids) => [...uuids, carousel.carousel_uID]);
        }
    }

    return (
        <li className="res-thumbnail" onClick={onProductClick}>
            <a href="#/" className={isChecked ? "box-inn selected" : "box-inn "}>
                <ImageItem src={`${carousel.thumbnail}?version=88`} alt={`thumbnail-${carousel.carousel_uID}`} />
                <div className="number-out">
                    <div className="number"><em className="far fa-check" /></div>
                </div>
            </a>
            
            <div 
                className='thumbnail-bar-container' 
                style={{ display: isChecked ? "block": "none" }}
            >
            </div>
        </li>
    )
}

const ImageItem = ({ src, alt }) => {
    const [imageLoaded, setImageLoaded] = React.useState(false);
  
    return (
        <div className="figure" style={{ position: 'relative', paddingTop: '100%' }}>
            <img 
                src={src} 
                alt={alt} 
                style={{ 
                    opacity: imageLoaded ? 1: 0, 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }} 
                onLoad={() => setImageLoaded(true)} 
            />
            {!imageLoaded && (
                <img 
                    src={withPrefix("assets/img/green-little-balls.gif")} 
                    alt={alt} 
                    style={{ 
                        opacity: imageLoaded ? 0: 1, 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            )}
        </div>
    )
}
