import React from "react";
import { useEffect, useState } from "react";
import Axios from "axios";
import { navigate, withPrefix } from "gatsby";
import Pagination from "@mui/material/Pagination";
import { useMediaQuery } from "@mui/material";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import { isLoggedIn } from "../services/auth";
import ListThumbnails from "../components/my-creations/listthumbnails";
import Swal from 'sweetalert2';
import { getUserFromAccessToken } from "../services/auth";
import { useDispatch, useSelector } from 'react-redux';
import { saveDownloadUrl, selectDownloadsState } from "../sagas/downloads/downloadsSlice";
import { getSavedCarousels, deleteCarousels, selectCarouselsState } from "../sagas/carousels/carouselsSlice";
import { DebounceInput } from 'react-debounce-input';

const Ursula_Token = process.env.GATSBY_URSULA_TOKEN;

export default function Layout() {
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
      return;
    }
  }, [])
  
  const currentUser = getUserFromAccessToken();
  const matchesMobile = useMediaQuery("(max-width:500px)");
  const dispatch = useDispatch();

  const { isSavingDownloadUrlSuccess, isSavingDownloadUrlFailed } = useSelector(selectDownloadsState);
  const { isGettingSavedCarouselsSuccess, isGettingSavedCarouselsFailed, SavedCarouselsDto, isDeletingCarouselsSuccess, isDeletingCarouselsFailed } = useSelector(selectCarouselsState);

  const [isExporting, setIsExporting] = useState(false);
  const [exportingStatus, setExportingStatus] = useState("");
  const [spentTime, setSpentTime] = useState("");

  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearch = (e) => {
    setPage(1);
    setSearchTerm(e.target.value);
  }

  //Page & PerPage handling: start
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = React.useState(1);
  const handlePageChange = async (event, page) => {
    event.preventDefault();
    setPage(page);
  };
  const handlePerPage = (e) => {
    setPerPage(parseInt(e.target.value))
  }
  //Page & PerPage handling: end

  //Fetch carousels: start
  const [isLoading, setIsLoading] = React.useState(1);
  const GetSavedCreations = (currentPage, perPage, search) => {
    setIsLoading(true);

    dispatch({
      type: getSavedCarousels.type,
      payload: {
        perPage,
        page: currentPage,
        search,
      }
    });
  }

  const [refreshPage, setRefreshPage] = useState(false);
  useEffect(() => {
    GetSavedCreations(page, perPage, searchTerm);
  }, [page, perPage, refreshPage, searchTerm])

  const [totalPages, setTotalPages] = React.useState(0);
  const [aggregatedCarousels, setAggregatedCarousels] = React.useState([]);
  useEffect(() => {
    if (isGettingSavedCarouselsSuccess) {
      setTotalPages(SavedCarouselsDto.pages);
      setAggregatedCarousels(SavedCarouselsDto.data);

      const myCreations = typeof window !== "undefined" ? JSON.parse(localStorage.getItem('myCreations') || '[]') : [];
      SavedCarouselsDto.data.forEach((carousel) => {
        if (!myCreations.some((creation) => creation._id === carousel._id)) {
          myCreations.push(carousel);
        }
      })
      localStorage.setItem("myCreations", JSON.stringify(myCreations));

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else if (isGettingSavedCarouselsFailed) {
      console.log("Unable to fetch carousels");
      setIsLoading(false);
    }
  }, [isGettingSavedCarouselsSuccess, isGettingSavedCarouselsFailed, SavedCarouselsDto])
  //Fetch carousels: end

  //Select carousel: start
  const [selectedUuids, setSelectedUuids] = React.useState([]);
  const [expandedCarouselUuid, setExpandedCarouselUuid] = React.useState([]);
  //Select carousel: end

  const onContinueBtnClicked = (e) => {
    e.preventDefault();
    if (selectedUuids.length === 0) {
      Swal.fire({
        icon: 'warning',
        text: "Select at least 1 carousel to continue.",
      })
    } else {
      localStorage.setItem("selectedCarouselUuids", JSON.stringify(selectedUuids));
      navigate("/my-creations-view");
    }
  };

  //Delete carousel: start
  const onDeleteAllClick = (e) => {
    e.preventDefault();
    if (selectedUuids.length === 0) {
      Swal.fire({
        icon: 'warning',
        text: "Select at least 1 carousel to delete.",
      })
    } else {
      const _ids = [];
      aggregatedCarousels.forEach((aggregateCarousel) => {
        aggregateCarousel.records.forEach((record) => {
          if (selectedUuids.includes(record.carousel_uID)) _ids.push(record._id);
        });
      });

      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true);

          dispatch({
            type: deleteCarousels.type,
            payload: { _ids },
          });
        }
      })
    }
  };

  useEffect(() => {
    if (isDeletingCarouselsSuccess) {
      setPage(1);
      setIsLoading(true);
      setRefreshPage((v) => !v);
    } else if (isDeletingCarouselsFailed) {
      console.log('Something went wrong');
      setIsLoading(false);
    }
  }, [isDeletingCarouselsSuccess, isDeletingCarouselsFailed])
  //Delete carousel: end

  //Download carousel: start
  const getForRequest = (flag) => {
    const reqData = [];

    aggregatedCarousels.forEach((aggregateCarousel) => {
      aggregateCarousel.records.forEach((record) => {
        if (selectedUuids.includes(record.carousel_uID)) {
          const skus = JSON.parse(record.carousel || {});
          const sku_keys = Object.keys(skus);
          reqData.push({ image: record.thumbnail, skus: sku_keys });
        };
      });
    });

    return reqData;
  }

  const onExportClick = (e) => {
    e.preventDefault();

    if (selectedUuids.length === 0) {
      Swal.fire({
        icon: 'warning',
        text: "Select at least 1 carousel to export.",
      })
    } else {
      setIsExporting(true);
      let reqData = getForRequest();
      Axios.post("https://ursula.99gens-api.com/api/admin/dielines/download/all", 
        reqData, 
        { 
          headers: { 'Authorization': `Bearer ${Ursula_Token}` }, 
        },
      )
        .then(async (response) => {
          let job_id = response.data.job_id;
          try {
            const res = await redirectWithStatus(job_id);
            setExportingStatus(res.data.status);
            setSpentTime(res.data.time_spent.created);
            if (res.data.status !== "completed") {
              if (res.data.status === "pending" || res.data.status === "queued" || res.data.status === "deffered" || res.data.status === "started") {
                let request_count = 0;
                const timer_1 = setInterval(() => {
                  request_count++;
                  if (request_count <= 5 || (request_count > 5 && request_count % 3 === 2)) {
                    redirectWithStatus(job_id)
                      .then((res) => {
                        setExportingStatus(res.data.status);
                        setSpentTime(res.data.time_spent.created);
                        if (
                          res.data.status === "completed" ||
                          res.data.status === "finished" ||
                          res.data.status === "failed" ||
                          res.data.status === "canceled" ||
                          res.data.status === "stopped"
                        ) {
                          setTimeout(() => {
                            setIsExporting(false);
                          }, 1000);
                          clearInterval(timer_1);
                          if (res.data.status === "finished" || res.data.status === "completed") {
                            dispatch({
                              type: saveDownloadUrl.type,
                              payload: { url: res.data.result.url },
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }, 60000);
              }
            }
          } catch (err) {
            console.log(err);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (isSavingDownloadUrlSuccess) {
      navigate("/downloads");
    } else if (isSavingDownloadUrlFailed) {
      console.log('Something went wrong');
    }
  }, [isSavingDownloadUrlSuccess, isSavingDownloadUrlFailed])

  const redirectWithStatus = (job_id) => {
    return Axios.get(`https://ursula.99gens-api.com/api/ques/job_status?job_id=${job_id}`, { 
      headers: { 'Authorization': `Bearer ${Ursula_Token}` }, 
    });
  }
  //Download carousel: end

  return (
    <>
      <div className="container-main my-creations">
        <Header></Header>
        <main className="content-main">
          <div className="order-reviews">
            <div className="container">
              <h1>My Creations</h1>
              
              {!isLoading && aggregatedCarousels.length >= 1 && (
                currentUser.role != "SUPERADMIN" ? (
                  <div className="btn-delete-all-out">
                    <a href="#" className="btn btn-blue btn-delete-all" onClick={onDeleteAllClick}>
                      Delete
                    </a>
                  </div>
                ) : (
                  <>
                    {isExporting && (
                      <div className="export-progress">
                        <img src={withPrefix("assets/img/green-little-balls.gif")} alt="loading" style={{ width: "5%" }} />
                        <span>
                          <span style={{ color: "#808080" }}>status: </span>
                          <span> {exportingStatus}</span>
                        </span>
                        <span>
                          <span style={{ color: "#808080", marginLeft: "20px" }}>progress:</span>
                          <span> {spentTime}</span>
                        </span>
                      </div>
                    )}

                    <div className="btn-delete-all-out">
                      <a href="#" style={{marginLeft:8}} className="btn btn-blue btn-delete-all" onClick={onDeleteAllClick}>
                        Delete
                      </a>
                      <a href="#" className="btn btn-blue btn-export" onClick={onExportClick}>
                        Export
                      </a>
                    </div>
                  </>
                )
              )}

              <div className="box-out">
                <div className="box">
                  <div className="search-text">
                    <DebounceInput
                      placeholder="Search by ID"
                      minLength={1}
                      debounceTimeout={1000}
                      onChange={handleSearch} 
                    />                    
                  </div>

                  <div className="review-table">
                    <ul>
                      <li className="table-heading">
                        <div className="col-1">ID</div>
                        <div className="col-2">Date</div>
                        <div className="col-3">Credits</div>
                        <div className="col-4">Selected</div>
                      </li>

                      {isLoading ? (
                        <li className="loading-container">
                          <img src={withPrefix("assets/img/green-little-balls.gif")} alt="loading" />
                        </li>
                      ) : (
                        aggregatedCarousels.map((aggregatedCarousel, index) => (
                          <ListThumbnails 
                            key={index}
                            carousels={aggregatedCarousel.records}
                            selectedUuids={selectedUuids}
                            setSelectedUuids={setSelectedUuids}
                            expandedCarouselUuid={expandedCarouselUuid}
                            setExpandedCarouselUuid={setExpandedCarouselUuid}
                          />
                        ))
                      )}
                    </ul>
                  </div>

                  {!isLoading && aggregatedCarousels.length == 0 && (
                    <div className="pagination" style={{ textAlign: "center" }}>
                      no data...
                    </div>
                  )}

                  <div className="pagination">
                    <Pagination count={totalPages} variant="outlined" shape="rounded" siblingCount={matchesMobile ? 0 : 1} onChange={handlePageChange} page={page} />
                    <select className="showCounts" value={perPage} onChange={handlePerPage}>
                      <option value="3">3/Page</option>
                      <option value="5">5/Page</option>
                      <option value="10">10/Page</option>
                    </select>
                  </div>

                  <div className="btm-btns">
                    <div className="shopping-cart">
                      <a href="#" className="cart-btn">
                        <em style={{marginRight:15}} className="far fa-shopping-bag" />
                        {selectedUuids.length}
                      </a>
                    </div>
                    <div className="btn-out">
                      <a href="#" className="btn btn-blue btn-continue" onClick={onContinueBtnClicked}>
                        Continue with selected
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="alert-box">
                <div className="box-inn">
                  <p>
                    <em>
                      <img style={{ marginRight: 5 }} src={withPrefix("assets/img/icon-info.png")} alt="icon-info" />
                    </em>
                    Selecting more than 6 creations may slow down and possibly crash your web browser. If you are experiencing this please return and select fewer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer1></Footer1>
      </div>
    </>
  );
}
